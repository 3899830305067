
.qanda-markdown ol,
.qanda-markdown ul {
    margin: 4px;
}

.qanda-markdown img,
.qanda-markdown canvas,
.qanda-markdown iframe,
.qanda-markdown video,
.qanda-markdown svg,
.qanda-markdown select,
.qanda-markdown textarea {
    align-self: stretch;
    max-width: 100%;
    box-sizing: border-box;
}

.qanda-markdown blockquote {
    border-left: 8px solid #fafafa;
    margin: 4px 0 4px 20px;
    padding-left: 4px;
}

.qanda-markdown pre {
    padding: .5rem;
    overflow-x: scroll;
    align-self: stretch;
}

.qanda-markdown pre,
.qanda-markdown code {
    background-color: #fafafa;
    border-radius: 4px;
}

.qanda-markdown code {
    padding: .3rem;
}

.qanda-markdown table {
    border-collapse: collapse;
}

.qanda-markdown th,
.qanda-markdown td {
    padding: 12px;
}


.qanda-markdown tr:hover {
    background-color: #F8F9F9;
}

.qanda-markdown th {
    border-bottom: 1px solid black;
}

.qanda-markdown td {
    border-bottom: 1px solid lightgrey;
}

.qanda-markdown tr:last-child td {
    border-bottom: none;
}

.qanda-markdown .qanda-table,
.qanda-markdown .math-display{
    align-self: center;
    margin-left: 0;
    overflow-x: scroll;
    max-width: 100%;
}

div.qanda-markdown{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    box-sizing: border-box;
}

