html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
  position: relative;
}

.hideScrollbar {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.hideScrollbar::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}

.coolShade {
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(228,242,255,1) 100%);
}

.qanda-pdf-marker{
  cursor: pointer;
  /*background-color: rgba(97, 106, 107, 0.7);*/
}

.qanda-pdf-marker:hover{
  transform: scale(1.1);
}

.qanda-pdf-marker:active{
  transform: scale(0.9);
}

.qanda-pdfViewer__noCursor{
  /*pointer-events: none;*/
  cursor: none !important;
  /*user-select: none !important;*/
}

.qanda-pdfViewer__noCursorNoEvents{
  pointer-events: none;
  cursor: none !important;
  user-select: none !important;
}

.qanda-pdfViewer__addMode{
  cursor: none !important;
  user-select: none !important;
}

.rpv-core__page-layer{
  box-shadow: none !important;
}

.rpv-core__annotation-layer {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.rpv-core__canvas-layer{
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.rpv-core__inner-page{
  background-color: #E5E7E9 !important;
  box-sizing: border-box !important;
}

.rpv-core__spinner {
  visibility: hidden !important;
}

.rpv-core__inner-pages {
  background-color: #E5E7E9 !important;
}

.rpv-core__viewer {
  background-color: #E5E7E9 !important;
}



